import React from "react";
import css from "./Footer.module.scss";
import { ReactComponent as Insta } from "../../Images/insta.svg";
import { ReactComponent as Fb } from "../../Images/fb.svg";
import { ReactComponent as In } from "../../Images/in.svg";
import { ReactComponent as Be } from "../../Images/be.svg";
import { ReactComponent as Wp } from "../../Images/WP/whatssvg.svg";
import { ReactComponent as Tel } from "../../Images/tel.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className={css.addresses}>
                <p>{t("getInTouch")}</p>
                <div className={css.addressMain}>
                    <div className={css.infoPlace}>
                        <p>{t("addressGyumri")}</p>
                        <p>+(374) 95 53-84-53</p>
                    </div>
                    <div className={css.infoPlace}>
                        <p>{t("address")}</p>
                        <p>+(374) 91 53-84-53</p>
                    </div>
                </div>
                <p>{t("workHours")}</p>
            </div>
            <div className={css.socials}>
                <a
                    href="https://www.instagram.com/creativelab__/"
                    className={css.social}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Insta />
                </a>
                <a
                    href="https://www.facebook.com/creativelab19/"
                    className={css.social}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Fb />
                </a>
                <a
                    href="https://www.linkedin.com/in/creative-lab-0775321b6/"
                    className={css.social}
                    target="_blank"
                    rel="noreferrer"
                >
                    <In />
                </a>
                <a
                    href="https://www.behance.net/creativagency1"
                    className={css.social}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Be />
                </a>
                {/* <a href="https://wa.me/message/26B3WQBTIHWBL1" className={css.social}>
                    <Wp/>
                </a> */}
                <a
                    href="https://t.me/realcreativelab"
                    className={css.social}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Tel />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
