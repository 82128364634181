import React from "react";
import css from "./Main.module.scss";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Home from "../Home/Home";
import { routes } from "../../Utils/Routes";
import ScrollToTop from "../../Utils/PageNavigationListener";
import { CONTACTS, HOME, PORTFOLIO, SERVICES } from "../../Utils/Urls";
import { servicesService } from "../../Utils/servicesService";
import JoinUs from "../Contacts/JoinUs/JoinUs";
import Portfolio from "../Portfolio/Portfolio";

const Main = () => {
    const pathSplit = useLocation().pathname.split("/");

    return (
        <main className={css.main}>
            <ScrollToTop />
            <Routes>
                {pathSplit[1] === "home" ? (
                    <Route path={HOME} element={<Home />} />
                ) : (
                    routes.map(({ id, path, element }) => {
                        return <Route key={id} path={path} element={element} />;
                    })
                )}
                {servicesService.map(({ id, path, element }) => {
                    return (
                        <Route
                            key={id}
                            path={SERVICES + "/" + path}
                            element={element}
                        />
                    );
                })}
                <Route path={PORTFOLIO + "/:id"} element={<Portfolio />} />
                <Route path={CONTACTS + "/join"} element={<JoinUs />} />
                <Route path={"*"} element={<Navigate to="home" />} />
            </Routes>
        </main>
    );
};

export default Main;
